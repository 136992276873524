import { createApp } from "vue";
import VueLazyload from "vue-lazyload";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./plugins/i18n";

import "./plugins/adaptive";
import "./styles/common.less";
import "./styles/vant.less";
import { myVanToast, myDialog } from "./plugins/vant";
import globalElementsMixins from "./plugins/elements";
import SvgIcon from "@/icons";
import api from "./service/api";

const app = createApp(App);
app.config.globalProperties.$Toast = myVanToast;
app.use(myDialog());
app.mixin(globalElementsMixins);
app.component("svg-icon", SvgIcon);
app.config.globalProperties.$api = api;
app.use(store).use(i18n).use(router).use(VueLazyload).mount("#app");
